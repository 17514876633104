<template>
  <div>
    
    <keep-alive>
      <Search ref="searchAbonents" v-on:clear-pre-search="prefixSearch = ''" v-on:apply-search="startSearch"  :prefixSearch="prefixSearch"/>
    </keep-alive>
  <v-data-table
      
      
      
      :headers="headers"
      loading-text="Завантаження..."
      no-data-text="Немає даних для відображення"
      :items="abonents"
      :sort-by="['billing_data.caller.port']"
      :sort-desc="[false]"
      
      :loading="loading"
      :group-by="['billing_data.caller.mac_switch']"
      :group-desc="[true]"
      show-group-by
      class="elevation-1 row-cursor"
      :footer-props="footer"
      focusable
      :dense="switch_danse_table || $vuetify.breakpoint.smAndDown"
      @click:row="onClickRow"
    >
      <template v-slot:top>
        <v-toolbar  flat color="secondary lighten-1" dark> 
          <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h2 class="accent--text">Порти <small class="error--text">( тест )</small></h2></v-toolbar-title>
          <v-spacer />
          <span title="Зменшити таблицю">  
            <v-switch
              v-if="!$vuetify.breakpoint.smAndDown"
              
              class="ml-2 mr-1"
              v-model="switch_danse_table"
              hide-details
            ></v-switch>
          </span>
          <v-btn title="Поновити данні" icon :loading="loading" @click="getAbonents()">
            <v-icon>autorenew</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:header.diferent_date>
        <v-icon color="grey" small>mdi-connection</v-icon>
      </template>

      <template v-slot:group.header="{group, items, isOpen, toggle, headers}">
        <td :colspan="headers.length"  @click="toggle">
          <span v-if="loading">Завантаження...</span>
          <div v-else class="d-flex justify-space-between">
            <span v-if="$vuetify.breakpoint.mdAndUp"></span>
            <span style="vertical-align: middle;">
              <template v-if="group ">
                <span class="mr-0 mr-md-3"><v-icon class="mr-2" style="vertical-align: sub;">mdi-dip-switch</v-icon>{{ !loading && findClearPort(items, group) && group }}</span>
                <br v-if="$vuetify.breakpoint.smAndDown">
                Порти без прив'язки: {{ !loading && findClearPort(items, group) || 'Нема' }}
              </template>
              <template v-else>Без комутатора</template>
            </span>
            <v-icon right> {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            
            </div>
        </td>
      </template>
      

      

    <!-- ---------------------------------------- Cols ----------------------------------------- -->
      
      
    
      <template #item.street__street="{ item }">
        <span :title="item && item.username + '\n' + item.full_name">{{ item.street && item.street.street || ''}}
        {{ item && `${item.house || ''}${item.letter || ''}`}}{{ item && item.corpus > 0 && ` (к.${item.corpus})` || '' }}
        {{ item && item.flat }}{{ item && item.tenand > 0 && ` (н.${item.tenand})` || '' }}</span>
        <span v-if="!item.id" class="grey--text" >{{ loading && 'Завантаження даних...' || 'Абонента не знайдено' }}</span>
      </template>
    
      <template #item.status="{ item }"> 
        <template v-if="item.billing_data && item.billing_data.online">
          <v-icon color="light-blue darken-1">mdi-web</v-icon>
        </template>
        <template v-else>
          <v-icon :color="item.billing_data && item.billing_data.user_service_status ? 'grey' : 'error'">mdi-web</v-icon>
        </template>
      </template>

      <template #item.diferent_date="{ item }"> 
        <template v-if="item && item.billing_data.last_login">
          <v-icon v-if="getDays(item.billing_data.last_login, new Date().toDateInputValue())" 
          color="error"
          title="Не користувались більше трьох місяців">
            mdi-connection
          </v-icon>
        </template>
        
      </template>

      <template #item.mac_switch="{ item }">
        {{ item && item.billing_data && item.billing_data.caller.mac_switch }}
      </template>
      
      <template #item.caller="{ item }">
        <span style="white-space: nowrap;" v-if="item.billing_data && item.billing_data.last_login" :title="item.billing_data.last_login">{{ item.billing_data.last_login.split(' ')[0] }} </span>
      </template>

      

      

    <!-- ---------------------------------------- /Cols ----------------------------------------- -->
    
    
    
    </v-data-table>
    <keep-alive>
      <AbonentInfoModal ref="AbonentInfoModal" backTo="Порти" v-on:update-data="updateTable += 1"/>
    </keep-alive>
    
    
  </div> 
</template>

<script>
  import axios from "axios"
  import Search from '@/components/Abonents/Search.vue'
  import searchParse from "@/components/Abonents/searchParse.js"


  import { mapGetters } from "vuex"
  export default {
    //props: [ 'search' ],
    name: 'find-ports-table',
    components: {
      Search,
      AbonentInfoModal:  () => import('@/components/Job/AbonentInfoModal.vue'),
    },
    data: () => ({
      switch_danse_table: false,
      updateTable: 0,
      mountedTable: false,
      prefixSearch: '',
      showGlobalSearch: false,
      search: '',
      abonents: [],
      countFilters: 0,
      loading: false,
      options: {},
      page: 1,
      filtersComponents: [],
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50,},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Адреса', value: 'street__street', sortable: false, groupable: false,},
        { text: 'Статус', value: 'status', sortable: false, groupable: false,},
        { text: 'Вхід', value: 'billing_data.last_login', sortable: true, groupable: false,},
        { text: 'Давній', value: 'diferent_date', sortable: false, groupable: false,},
        { text: 'Switch', value: 'billing_data.caller.mac_switch',  sortable: false, },
        { text: 'Комутатор', value: 'mac_switch',  sortable: false, groupable: false, },
        { text: 'VLAN', value: 'billing_data.caller.vlan',  sortable: false, groupable: false,},
        { text: 'Порт', value: 'billing_data.caller.port',  sortable: true, groupable: false,},
        { text: 'Абонент', value: 'billing_data.caller.mac_abonent',  sortable: false, groupable: false,},
        
      ],
      footer: {
          "items-per-page-options": [200],
          "items-per-page-text": " ", //"Абонентів на сторінці:"
          "items-per-page-all-text": "Всі",
          "show-first-last-page": true,
          "show-current-page": true,
      },
    }),
    computed: {
      ...mapGetters({
        user: "auth/user"
      }),
      
    },
    mounted (){
      this.$nextTick(function () {
        this.mountedTable = true
      })
    },
    watch: {
      /*search: function(){
        this.checkGetAbonents()
      },*/
      options: {
        handler (val, oldVal) {
          oldVal.page && this.getAbonents();

        },
        deep: true,
      },
    },
    methods: {
      searchParse: searchParse,
       
    
      startSearch: function(search){
        this.search = search 
        
        this.getAbonents()
        //console.log(search)
      },
      openAbonentInfoModal(paramsUrlId){
        this.onClickRowStop = true
        this.$refs.AbonentInfoModal.paramsUrlId = paramsUrlId
        this.$refs.AbonentInfoModal.dialog = true
      },
      onClickRow(item){
        item.id && this.openAbonentInfoModal(item.id)
      },
      showRouter(){
        //console.log(this.$refs)
      },
      /*
       
       */
      async getAbonentsBillingFirst(){
        this.loading = true
        try {
          const { data } = await axios.get(this.$router.app.devUrlBillingAPI, {params: {req_cmd: "getAbonents",  ...this.filterFormat()}});
          if(data.resp_status == "OK"){
            /*
            let tempAbonents =[]
            data.resp_result.result.forEach(elem => elem.billing_data.caller.mac_switch && tempAbonents.push(elem))
            this.abonents = tempAbonents
            */
            this.abonents = data.resp_result.result
            this.getAbonentsSecond()
          }else {
            this.abonents = []
            this.paginations = {}
            this.searchHint = ''
            this.$router.app.$sheet.show("Помилка!", data.resp_errmsg)
            this.loading = false
          }
          
        }catch(err){
          this.loading = false
          this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err);
        }
      },
      getAbonentsSecond(){
        if(!this.abonents){return}
        let pcodes = []
        //this.abonents.forEach(elem => {pcodes += elem.billing_data.pcode + ','})
        this.abonents.forEach(elem => {pcodes.push(elem.billing_data.pcode)})
        axios({
          method: "post",
          url: "/api/cabletv/get_users_pcode/",
          data: {user_pcode_list: pcodes}
        })
        .then(response => {
          if(response.data){
            if(response.data.length > 0){
              response.data.forEach(elem => {
                delete elem.billing_data
                Object.assign(this.abonents.find(x => x.billing_data.pcode == elem.user_pcode), elem)
              })
            }
          }
        })
        .catch(err => {
          //console.log(err);
          this.$router.app.$sheet.show("Помилка пошуку абонентів!", err);
        })
        .finally(() => (this.loading = false))
      },
      async getAbonents(){
        if(!this.search){
          this.abonents = []
          return
        }
          this.getAbonentsBillingFirst()
      },
      
      
      
      filterFormat() {
        this.countFilters = 0
        const temp = {};
        
        temp.page = this.options.page;
        temp.itemsPerPage = 200;
        //if(this.search) temp.search = this.search
        

        if(this.search){
          //console.log(this.searchParse(this.search))
          Object.assign(temp, this.searchParse(this.search))
          
          /*if(this.checkTypeRequestBilling()){
            Object.assign(temp, await  this.searchParse2(this.search))
          }else Object.assign(temp, this.searchParse(this.search))*/
        } 
        //console.log(temp)
        return temp; 
      },
      getDays(date1, date2){
        var d1, d2;
        if(typeof date1=='string')
          d1=new Date(date1);
        else
          d1=new Date(date1.getTime());
        if(typeof date2=='string')
          d2=new Date(date2);
        else
          d2=new Date(date2.getTime());
        return (Math.abs(d2.getTime()-d1.getTime())/86400000) > 92;
      },
      groupToRanges(arr) {
        let result = [];
        let currentRange = [arr[0]];
        for (let i = 1; i < arr.length; i++) {
            if (arr[i] === arr[i - 1] + 1) {
                // Value is in sequence, add it to the current range
                currentRange.push(arr[i]);
            } else {
                // Value is not in sequence, finish the current range and add it to the result
                result.push(this.formatRange(currentRange));
                // Start a new range
                currentRange = [arr[i]];
            }
        }
        // Add the last current range to the result
        result.push(this.formatRange(currentRange));

        return result;
      },
      formatRange(range) {
        if (range.length === 1) {
            return range[0].toString();
        } else {
            return range[0] + '-' + range[range.length - 1];
        }
      },
      sortArray(arr) {
          return arr.slice().sort(function(a, b) {
              return a - b; // Для сортування у зростаючому порядку
              // або return b - a; // Для сортування у спадаючому порядку
          });
      },
      findClearPort(items, group){
        let portsClear = []
        let portsInLink = []
        let portsGroup = items.filter(x => x.billing_data.caller.mac_switch == group)
        portsGroup.forEach(elem => {
          portsInLink.push(elem.billing_data.caller.port)
        })
        for (let index = 1; index < this.sortArray(portsInLink).at(-1); index++) {
          portsInLink.indexOf(index) == -1 && portsClear.push(index)
          
        }
        //console.log(portsClear.join(',') + '...')
        //return portsClear.join(',').replace(/,/g, ', ') + ' ...'
        return portsClear.length > 0 && this.groupToRanges(portsClear).join(',').replace(/,/g, ', ')
      }

    },
  }
</script>

<style>
  .row-cursor tr {
    cursor: pointer;
  }
  .td_background tr td:first-child{
    background: #eee
  }
  .td_background_dark tr td:first-child{
    background: rgb(88, 88, 88)
  }
</style>


