<template>
 

    <v-form 
    @submit.prevent="searchNow()"
    >
      <v-row class="text-center mt-1 mb-0">
        <v-spacer></v-spacer>
        <v-col cols=12 md=8 lg=6 xl=5 class=" pb-0 " :class="$route.name != 'home' ? ' pt-0' : ''">
        <v-text-field
            v-model="search"
            ref="search"
            :loading="loadingSearch"
            :prefix="prefixSearch"
            :append-icon="$route.name != 'home' ? 'mdi-magnify' : ''"
            :prepend-inner-icon="$route.name == 'home' ? 'mdi-magnify' : ''"
            name="search"
            label="Пошук"
            class="search pb-3"
            :class="$vuetify.breakpoint.mobile ? 'pb-3' :''"
            persistent-hint
            
            :dense="$vuetify.breakpoint.smAndDown"
            :filled="$route.name != 'home' && $vuetify.breakpoint.mdAndUp"
            :rounded="$route.name != 'home' && $vuetify.breakpoint.mdAndUp"
            :single-line="$route.name != 'home' && $vuetify.breakpoint.mdAndUp"
            hide-details
            :hint="false && searchHint && `Знайдено: ${searchHint}` || ''"
            :outlined="$route.name != 'home' && $vuetify.breakpoint.smAndDown"
            clearable
            @click:prepend-inner="searchNow()"
            @click:append="searchNow()"
            @click:clear="search = '', searchNow()"
            
            >
            
            <template v-slot:prepend v-if="$route.name == 'abonents'">
              <v-menu
                
                dense
                style="top: -12px"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    title="Тип пошуку"
                  >
                      mdi-text-search-variant
                </v-icon>
                </template>
                <v-list dense>
                  <v-subheader>ПОШУК:</v-subheader>
                  <v-list-item-group dense mandatory v-model="searchGroup">
                    <v-list-item v-for="(item, index) in searchGroupData" :key="index">
                     <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    
                  </v-list-item-group>
                  
                </v-list>
              </v-menu>
              <v-tooltip v-if="searchGroup == 2 || searchGroup == 3" bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="error" class="ml-1">mdi-information</v-icon>
                </template>
                <span>При поточному типі пошуку <br>не працює сортування по останньому входу, <br>а також фільтри: "послуга інтернет", <br>"тарифи інтернет", "онлайн"</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col><!---->
        <v-spacer></v-spacer>
      </v-row>
    </v-form>
</template>

<script>
  export default {
    name: 'Search',
    props: ["searchHint", "prefixSearch", "searchGroupData"],
    data: () => ({
      searchGroup: 0,
      loadingSearch: false,
      search: "",
    }),
    created: function () {
      //this.debouncedLoading = require('lodash').debounce(this.searchTimeOff, 650);
    },
    mounted() {
      this.$nextTick(function () {
        this.$refs.search && this.$refs.search.focus()
        this.search = this.$route.params.search || ""
        this.search && this.$emit('apply-search', this.search)
      }) 
    },
    watch: {
      search(){
        this.searchGroup == 0 && this.$emit('clear-pre-search')
        //this.loadingSearch = this.search && true || false

        //this.$route.name != 'home' && this.debouncedLoading()
      },
      searchGroup(){
        //console.log("search component searchGroup: ", this.searchGroup)
        
        this.$emit('change-search-group', this.searchGroup)
        this.search && this.search.length > 3 && this.$emit('apply-search', this.search)
      }
    },
    methods: {
      searchNow(){
        //console.log("search submit")
        //if(this.search && 2 > this.search.length) return
        this.$route.name == 'home' && this.$router.push({ name: 'abonents', params: { search: this.search }})
        this.$emit('apply-search', this.search)
        
      },
      searchTimeOff(){
        this.$emit('apply-search', this.search);
      },
      searchPhones(phone){
        this.search = phone
        this.searchNow()
      }
    }
    
  }
</script>

<style>
.search input {
  padding-left: 5px;
}
.v-text-field__prefix{
  color: grey
}
</style>