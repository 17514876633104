<template>
    <v-container class="" :style="{'max-width': '1300px'}">
      
      <TableFindPorts :user="user"/>
    
    </v-container>
</template>

<script>
//import axios from "axios"
import {mapGetters} from 'vuex'
import TableFindPorts from '@/components/FindPorts/TableFindPorts.vue'

export default {
    name: 'find-ports',
    components:{
      TableFindPorts
    },

    data: () => ({
      loading: false,
    }),
    mounted(){
      //console.log("mounted page Notification")
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    methods:{
      //
    }

}
    

</script>
