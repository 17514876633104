var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('keep-alive',[_c('Search',{ref:"searchAbonents",attrs:{"prefixSearch":_vm.prefixSearch},on:{"clear-pre-search":function($event){_vm.prefixSearch = ''},"apply-search":_vm.startSearch}})],1),_c('v-data-table',{staticClass:"elevation-1 row-cursor",attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Немає даних для відображення","items":_vm.abonents,"sort-by":['billing_data.caller.port'],"sort-desc":[false],"loading":_vm.loading,"group-by":['billing_data.caller.mac_switch'],"group-desc":[true],"show-group-by":"","footer-props":_vm.footer,"focusable":"","dense":_vm.switch_danse_table || _vm.$vuetify.breakpoint.smAndDown},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"secondary lighten-1","dark":""}},[_c('v-toolbar-title',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''},[_c('h2',{staticClass:"accent--text"},[_vm._v("Порти "),_c('small',{staticClass:"error--text"},[_vm._v("( тест )")])])]),_c('v-spacer'),_c('span',{attrs:{"title":"Зменшити таблицю"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-switch',{staticClass:"ml-2 mr-1",attrs:{"hide-details":""},model:{value:(_vm.switch_danse_table),callback:function ($$v) {_vm.switch_danse_table=$$v},expression:"switch_danse_table"}}):_vm._e()],1),_c('v-btn',{attrs:{"title":"Поновити данні","icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.getAbonents()}}},[_c('v-icon',[_vm._v("autorenew")])],1)],1)]},proxy:true},{key:"header.diferent_date",fn:function(){return [_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("mdi-connection")])]},proxy:true},{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length},on:{"click":toggle}},[(_vm.loading)?_c('span',[_vm._v("Завантаження...")]):_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span'):_vm._e(),_c('span',{staticStyle:{"vertical-align":"middle"}},[(group )?[_c('span',{staticClass:"mr-0 mr-md-3"},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"vertical-align":"sub"}},[_vm._v("mdi-dip-switch")]),_vm._v(_vm._s(!_vm.loading && _vm.findClearPort(items, group) && group))],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('br'):_vm._e(),_vm._v(" Порти без прив'язки: "+_vm._s(!_vm.loading && _vm.findClearPort(items, group) || 'Нема')+" ")]:[_vm._v("Без комутатора")]],2),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)])]}},{key:"item.street__street",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item && item.username + '\n' + item.full_name}},[_vm._v(_vm._s(item.street && item.street.street || '')+" "+_vm._s(item && ("" + (item.house || '') + (item.letter || '')))+_vm._s(item && item.corpus > 0 && (" (к." + (item.corpus) + ")") || '')+" "+_vm._s(item && item.flat)+_vm._s(item && item.tenand > 0 && (" (н." + (item.tenand) + ")") || ''))]),(!item.id)?_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.loading && 'Завантаження даних...' || 'Абонента не знайдено'))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.billing_data && item.billing_data.online)?[_c('v-icon',{attrs:{"color":"light-blue darken-1"}},[_vm._v("mdi-web")])]:[_c('v-icon',{attrs:{"color":item.billing_data && item.billing_data.user_service_status ? 'grey' : 'error'}},[_vm._v("mdi-web")])]]}},{key:"item.diferent_date",fn:function(ref){
var item = ref.item;
return [(item && item.billing_data.last_login)?[(_vm.getDays(item.billing_data.last_login, new Date().toDateInputValue()))?_c('v-icon',{attrs:{"color":"error","title":"Не користувались більше трьох місяців"}},[_vm._v(" mdi-connection ")]):_vm._e()]:_vm._e()]}},{key:"item.mac_switch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.billing_data && item.billing_data.caller.mac_switch)+" ")]}},{key:"item.caller",fn:function(ref){
var item = ref.item;
return [(item.billing_data && item.billing_data.last_login)?_c('span',{staticStyle:{"white-space":"nowrap"},attrs:{"title":item.billing_data.last_login}},[_vm._v(_vm._s(item.billing_data.last_login.split(' ')[0])+" ")]):_vm._e()]}}])}),_c('keep-alive',[_c('AbonentInfoModal',{ref:"AbonentInfoModal",attrs:{"backTo":"Порти"},on:{"update-data":function($event){_vm.updateTable += 1}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }