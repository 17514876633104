function searchParse(search, searchGroupName){
    //console.log(searchGroupName)
    if(searchGroupName){
      return {[searchGroupName]: search} 
    }

    let house_plus_letter, house_plus_letter2;
    search = search.replace(/\s+/g, ' ').trim() // Убираем лишние пробелы
    if (search.match(/ /g)){ // Если несколько слов
      let result = search.split(' ')
      if (search.replace(/\s+/g, '').match(/^[йцукенгшщзхїфівапролджєячсмитьбюЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮ]+$/)){ //Если в запросе несколько слов и все только из букв
        this.prefixSearch = 'по ПІБ: '
        return {full_name: search}
      }
      else if(result[0].match(/^-{0,1}\d+$/)){ // Если в первом слове только цифры
        this.prefixSearch = 'по адресі: '
        if (result.length == 4) {
          return {street_str: result[0], house: result[1], letter: result[2], flat: result[3]}
        }
        else if (result.length == 3) {
          house_plus_letter = result[1].match(/(^[0-9]+)([А-я]$)/i) 
          if (result[1].match(/^\d+$/)) { //Якщо другий елемент число
            return {street_str: result[0], house: result[1], flat: result[2]}
          }
          else if (house_plus_letter){ // Якщо друге слово "дім + буква"
            return {street_str: result[0], house: house_plus_letter[1], letter: house_plus_letter[2], flat: result[2]}
          } 
          else {
            return {house: result[0], letter: result[1], flat: result[2]}
          }
        }  
        else if (result.length == 2) {
          house_plus_letter = result[0].match(/(^[0-9]+)([А-я]$)/i) 
          if (house_plus_letter){ // Если найдено слитное "дом + буква"
            return {house: house_plus_letter[1], letter: house_plus_letter[2], flat: result[1]}
          }
          else if (result[1].match(/^-{0,1}\d+$/)){ // Если второе слово из цифр
            return {house: result[0], flat: result[1]} 
          } 
          else return {house: result[0], letter: result[1]} 
        }
        
      }else { // Если в первом слове не только цифры
        this.prefixSearch = 'по адресі: '
        if (result.length == 4) {
          return {street_str: result[0], house: result[1], letter: result[2], flat: result[3]}
        }
        else if (result.length == 3){
          house_plus_letter = result[1].match(/(^[0-9]+)([А-я]$)/i)
          if (house_plus_letter){ // Если найдено слитное "дом + буква"
            return {street_str: result[0], house: house_plus_letter[1], letter: house_plus_letter[2], flat: result[2]}
          }
          else if (result[2].match(/^-{0,1}\d+$/)){ // Если третье слово из цифр
            return {street_str: result[0], house: result[1], flat: result[2]}
            }
          else {// Если третье слово из букв
            return {street_str: result[0], house: result[1], letter: result[2]} 
          }
          
        }
        else if (result.length == 2) {
          house_plus_letter = result[0].match(/(^[0-9]+)([А-я]$)/i) 
          house_plus_letter2 = result[1].match(/(^[0-9]+)([А-я]$)/i) 
          if(house_plus_letter){ // Если в первом слове найдено слитное "дом + буква"
            return {house: house_plus_letter[1], letter: house_plus_letter[2], flat: result[1]} 
          }else if (house_plus_letter2){// Если во втором слове найдено слитное "дом + буква"
            return {street_str: result[0], house: house_plus_letter2[1], letter: house_plus_letter2[2]}
          }else return {street_str: result[0], house: result[1]}
        } 
        
      }
    }else{ // Если одно слово
      if (search.match(/^-{0,1}\+*\d+$/)) { // Если в слове только цифры match(/^-{0,1}\d+$/)
        if(Number(search) > 65000 && 199000 > Number(search)) { // 
          this.prefixSearch = 'по рахунку (І): '
          return { inet_id: search }
        }
        else if(Number(search) > 300000 && 999999 > Number(search)) { // 
          this.prefixSearch = 'по рахунку (ТБ): '
          return { id: search }
        }
        else if(search.match(/^-{0,1}\+*\d+$/) && search.length == 15) { // 
          this.prefixSearch = 'по pcode: '
          return { user_pcode: search }
        }
        else if(14 > search.length && search.length > 6 ) { // 
          this.prefixSearch = 'по телефону: '
          return { phone: search }
        }else if(4 > search.length && search.length > 0 ) { // 
          this.prefixSearch = 'по будинку: '
          return { house: search }
        }
        else{
          this.prefixSearch = 'інше: '
          return { search: search }
        }
      } 
      else { // Якщо в слові не тільки цифри
        house_plus_letter = search.match(/(^[0-9]+)([А-я]$)/i)
        if (house_plus_letter){ // Если найдено слитное "дом + буква"
          return {house: house_plus_letter[1], letter: house_plus_letter[2]}
        }
        else if(search.match(/^[йцукенгшщзхїфівапролджєячсмитьбюЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮ]+$/)){ // Якщо в запиті українські літри
          this.prefixSearch = 'по прізвищу: '
          return { full_name: search }
        }
        else if(search.match(/^[0-9a-f]{2}((-|:)[0-9a-f]{2}){5}$/i)){ // Якщо знайдено мак адресу
          this.prefixSearch = 'по mac kom: '
          return { mac: search }
        }
        else if(search.match(/\w/)){ // Якщо суміш будь-яких символів
          this.prefixSearch = 'по логіну: '
          return { username: search }
        }
        this.prefixSearch = 'інше: '
        return { search: search }
      }
      
    }
  }

  export default searchParse